import './style.css'
import { addCameraShadowGUI, generateGuiPanel } from './functions'
import config from './config'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import Stats from 'three/examples/jsm/libs/stats.module.js'

// Globals
let guiFolder, params
let helpersToUpdate = []


// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

// Loaders
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()

// Stats
const stats = Stats()
document.body.appendChild(stats.dom)

// Debug
const gui = new dat.GUI({ width: 400 })

// Canvas
const canvas = document.querySelector('canvas')

// Scene
const scene = new THREE.Scene()

// Enviroment map
const enviromentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/3/px.jpg',
    '/textures/environmentMaps/3/nx.jpg',
    '/textures/environmentMaps/3/py.jpg',
    '/textures/environmentMaps/3/ny.jpg',
    '/textures/environmentMaps/3/pz.jpg',
    '/textures/environmentMaps/3/nz.jpg'
])
enviromentMap.encoding = THREE.sRGBEncoding
scene.environment = enviromentMap

const updateLabelMap = () => {
    scene.traverse((child) => {

        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            if (child.name == "papel") {
                const msgTexture = new THREE.TextureLoader().load('/textures/message.jpg');
                msgTexture.flipY = false
                const material = new THREE.MeshBasicMaterial({ map: msgTexture });
                child.material.map = msgTexture
                child.material.metalness = 1
                child.material.roughness = 1
                child.material.envMapIntensity = config.material.envMapIntensity
                child.material.needsUpdate = true
                child.material = material
                child.castShadow = true
                child.receiveShadow = true

            }
        }
    })
}


// Update all materials
const updateAllMaterials = () => {
    scene.traverse((child)=>{
        
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            // If you add enviromerMap to 
            // scene.enviroment property 
            // you don't need do this ⤵
            // child.material.envMap = enviromentMap
            child.material.envMapIntensity = config.material.envMapIntensity
            child.material.roughness = config.material.roughness
            child.material.metalness = config.material.metalness
            child.material.color.set(new THREE.Color(config.material.color))
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x000000, 0);

// Set the physicallyCorrectLights property in the renderer to true
// When this property is true, 1 intensity is less intensity than when is false
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.LinearToneMapping
renderer.toneMappingExposure = 5
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

guiFolder = gui.addFolder('Renderer')

guiFolder
    .add(renderer, 'toneMapping', {
        No: THREE.NoToneMapping,
        Linear: THREE.LinearToneMapping,
        Reinhard: THREE.ReinhardToneMapping,
        Cineon: THREE.CineonToneMapping,
        ACESFilmic: THREE.ACESFilmicToneMapping
    })
    .onFinishChange(() => {
        renderer.toneMapping = Number(renderer.toneMapping)
        updateAllMaterials()
    })

guiFolder.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)


// Materials
guiFolder = gui.addFolder('Material')
guiFolder.addColor(config.material, 'color').onChange(updateAllMaterials).name('Specular Color')
guiFolder.add(config.material, 'metalness').min(0).max(1).step(0.001).name('Metalness').onChange(updateAllMaterials)
guiFolder.add(config.material, 'roughness').min(0).max(1).step(0.001).name('Roughness').onChange(updateAllMaterials)
guiFolder.add(config.material, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)
guiFolder.add(config.material, 'bgEnviromentMap').name('Show enviroment map').onChange(()=>{ 
    scene.background = config.material.bgEnviromentMap ? enviromentMap : null
})


// Ambient light 
const ambientLight = new THREE.AmbientLight()
params = config.lights.ambient.gui
ambientLight.color = new THREE.Color(params.data.color)
ambientLight.intensity = params.data.intensity.default
ambientLight.visible = config.lights.ambient.visible
scene.add(ambientLight)

guiFolder = generateGuiPanel(gui, ambientLight, params)
guiFolder.add(ambientLight, 'visible').name('Show')

// Directional lights
config.lights.directional.forEach((lightData, i)=>{
    const d = 2
    let position = lightData.gui.data.position.data
    const light = new THREE.DirectionalLight(lightData.gui.data.color, lightData.gui.data.intensity.default)
    light.position.set(position.x.default, position.y.default, position.z.default)

    light.shadow.mapSize.set(1024, 1024)
    light.castShadow = true
    light.shadow.camera.near = lightData.shadowCamera.near
    light.shadow.camera.far = lightData.shadowCamera.far
    light.shadow.camera.left = lightData.shadowCamera.left;
    light.shadow.camera.right = lightData.shadowCamera.right;
    light.shadow.camera.top = lightData.shadowCamera.top;
    light.shadow.camera.bottom = lightData.shadowCamera.bottom;

    const lightHelper = new THREE.DirectionalLightHelper(light, .5, 0x000000)
    const lightCameraHelper = new THREE.CameraHelper(light.shadow.camera)
    helpersToUpdate.push(lightHelper, lightCameraHelper)

    light.visible = lightData.visible
    lightHelper.visible = false
    lightCameraHelper.visible = false

    scene.add(light)
    scene.add(lightHelper)
    scene.add(lightCameraHelper)

    guiFolder = generateGuiPanel(gui, light, lightData.gui)
    addCameraShadowGUI(guiFolder, light.shadow.camera, d)
    guiFolder.add(light, 'visible').name('Show')
    guiFolder.add(lightHelper, 'visible').name("Show helper")
    guiFolder.add(lightCameraHelper, 'visible').name("Show camera shadow helper")
})

// Point lights
config.lights.points.forEach((lightData, i) => {
    let position = lightData.data.position.data
    const light = new THREE.PointLight(lightData.data.color, lightData.data.intensity.default, lightData.data.distance.default)
    light.position.set(position.x.default, position.y.default, position.z.default)
    light.visible = false
    scene.add(light)

    const lightHelper = new THREE.PointLightHelper(light, .25, 0x000000)
    helpersToUpdate.push(lightHelper)

    scene.add(lightHelper)
    lightHelper.visible = lightData.visible

    guiFolder = generateGuiPanel(gui, light, lightData)
    guiFolder.add(light, 'visible').name('Show')
    guiFolder.add(lightHelper, 'visible').name("Show helper")
})

// Spot lights
config.lights.spots.forEach((lightData, i) => {
    const position = lightData.data.position.data
    const targetPosition = lightData.data.target.data
    const light = new THREE.SpotLight(
        lightData.data.color,
        lightData.data.intensity.default,
        lightData.data.distance.default,
        lightData.data.angle.default,
        lightData.data.penumbra.default,
        lightData.data.decay.default
    )

    light.position.set(position.x.default, position.y.default, position.z.default)
    scene.add(light)

    light.target.position.set(targetPosition.x.default, targetPosition.y.default, targetPosition.z.default)
    scene.add(light.target)

    const lightHelper = new THREE.SpotLightHelper(light, 0x000000)
    scene.add(lightHelper)

    helpersToUpdate.push(lightHelper)

    light.visible = lightData.visible
    lightHelper.visible = false

    guiFolder = generateGuiPanel(gui, light, lightData)
    guiFolder.add(light, 'visible').name('Show')
    guiFolder.add(lightHelper, 'visible').name("Show helper")
})

params = {}
params.play = (a, b, c) => {
    timeScale *= -1

    window.playButton.domElement.parentElement.parentElement.setAttribute('disabled', true)

    if (timeScale > 0) {
        activeAction.reset()
        activeAction.clampWhenFinished = true
        activeAction.timeScale = timeScale
        activeAction.setLoop(THREE.LoopOnce, 1)
        activeAction.setDuration(activeAction.getClip().duration * 2)
        activeAction.play()
    }
    else {
        activeAction.timeScale = timeScale
        activeAction.paused = false
    }
}

// Models
const group = new THREE.Group()
scene.add(group)

// const axesHelper = new THREE.AxesHelper()
// group.add(axesHelper)


let mixer
let activeAction
let timeScale  = -1
let cookieParam = document.location.search.split('?cookie=')[1]
let modelFile = cookieParam ? `models/FortuneCookie_break_${cookieParam}.glb` : 'models/FortuneCookie_break_0001.glb'

gltfLoader.load(
    modelFile,
    
    (gltf) => {

        let gltfModel = gltf.scene
        gltfModel.scale.set(0.3, 0.3, 0.3)

        
        config.models.forEach((modelData, i) => {
            let model = gltfModel.clone()
            let position = modelData.data.position.data
            let rotation = modelData.data.rotation.data
            model.position.set(position.x.default, position.y.default, position.z.default)
            model.rotation.set(rotation.x.default, rotation.y.default, rotation.z.default)
            generateGuiPanel(gui, model, modelData)
            
            mixer = new THREE.AnimationMixer(model)
            if (gltf.animations.length) {
                const animationAction = mixer.clipAction(gltf.animations[0])
                activeAction = animationAction
                activeAction.timeScale = timeScale
            }

            group.add(model)

        })

        let groupPosition = config.group.data.position.data
        let groupRotation = config.group.data.rotation.data
        group.position.set(groupPosition.x.default, groupPosition.y.default, groupPosition.z.default)
        group.rotation.set(groupRotation.x.default, groupRotation.y.default, groupRotation.z.default)
        guiFolder = generateGuiPanel(gui, group, config.group)

        updateAllMaterials()
        //updateLabelMap()

        window.playButton = gui.add(params, 'play').name('Play forward/backward')

    }
)

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Camera
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100)
camera.position.set(4, 1, - 4)
scene.add(camera)

// const aspectRatio = sizes.width / sizes.height
// const camera = new THREE.OrthographicCamera(
//     - 4.5 * aspectRatio,
//     4.5 * aspectRatio,
//     4.5,
//     -4.5,
//     0.1,
//     100
// )
// camera.position.set(4, 1, -4)
// scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

const clock = new THREE.Clock()
// Animate
const tick = () =>
{

    // const elapsedTime = clock.getElapsedTime()
    const delta = clock.getDelta()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // Update controls
    controls.update()

    // Update light helpers
    helpersToUpdate.forEach((helper)=>{ helper.update() })

    // Render
    renderer.render(scene, camera)

    if (mixer) {
        mixer.update(delta)
    }

    stats.update()
}

tick()