export default {
    material: {
        envMapIntensity: 0,
        metalness: 0.1,
        roughness: 0.4,
        color: 0xffffff,
        bgEnviromentMap: false

    },
    group: {
        folderName: 'Modeles groups',
        data: {
            position: {
                data: {
                    x: {
                        min: -5, max: 5, step: 0.001, default: 0
                    },
                    y: {
                        min: -5, max: 5, step: 0.001, default: 0
                    },
                    z: {
                        min: -5, max: 5, step: 0.001, default: 0
                    }
                }
            },
            rotation: {
                data: {
                    x: {
                        min: -Math.PI, max: Math.PI, step: 0.001, default: 0
                    },
                    y: {
                        min: -Math.PI, max: Math.PI, step: 0.001, default: 2.5
                    },
                    z: {
                        min: -Math.PI, max: Math.PI, step: 0.001, default: 0
                    }
                }
            }
        }
    },
    models: [
        {
            folderName: 'Model 01',
            data: {
                position: {
                    data: {
                        x: {
                            min: -5, max: 5, step: 0.001, default: 0
                        },
                        y: {
                            min: -5, max: 5, step: 0.001, default: -.5
                        },
                        z: {
                            min: -5, max: 5, step: 0.001, default: 0
                        }
                    }
                },
                rotation: {
                    data: {
                        x: {
                            min: -Math.PI, max: Math.PI, step: 0.001, default: 0
                        },
                        y: {
                            min: -Math.PI, max: Math.PI, step: 0.001, default: 0
                        },
                        z: {
                            min: -Math.PI, max: Math.PI, step: 0.001, default: 0
                        }
                    }
                }
            }
        }
        // ,
        // {
        //     folderName: 'Model 02',
        //     data: {
        //         position: {
        //             data:{
        //                 x: {
        //                     min: -5, max: 5, step: 0.001, default: 0
        //                 },
        //                 y: {
        //                     min: -5, max: 5, step: 0.001, default: 0
        //                 },
        //                 z: {
        //                     min: -5, max: 5, step: 0.001, default: -2
        //                 }
        //             }
        //         },
        //         rotation: {
        //             data: {
        //                 x: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: Math.PI * 0.5
        //                 },
        //                 y: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: Math.PI * 0.25
        //                 },
        //                 z: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: 0
        //                 }
        //             }
        //         }
        //     }
        // },
        // {
        //     folderName: 'Model 03',
        //     data: {
        //         position: {
        //             data: {
        //                 x: {
        //                     min: -5, max: 5, step: 0.001, default: 0
        //                 },
        //                 y: {
        //                     min: -5, max: 5, step: 0.001, default: 0
        //                 },
        //                 z: {
        //                     min: -5, max: 5, step: 0.001, default: 1
        //                 }
        //             }
        //         },
        //         rotation: {
        //             data: {
        //                 x: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: Math.PI * 0.25
        //                 },
        //                 y: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: Math.PI * 0.5
        //                 },
        //                 z: {
        //                     min: -Math.PI, max: Math.PI, step: 0.001, default: 0
        //                 }
        //             }
        //         }
        //     }
        // }
    ],
    lights: {
        ambient: {
            visible: true,
            gui: {
                folderName: 'Ambient Light',
                data: {
                    color: 0xffffff,
                    intensity: {
                        min: 0, max: 1, step: 0.0001, default: .8
                    }
                }
            }
        },
        directional: [
            {
                visible: false,
                shadowCamera: {
                    near: 0.7,
                    far: 3.7,
                    left: -2,
                    right: 2,
                    top: 2,
                    bottom: -2
                },
                gui: {
                    folderName: 'Direction light TOP',
                    data: {
                        color: 0xffffff,
                        intensity: {
                            min: 0, max: 10, step: 0.001, default: .5
                        },
                        position: {
                            data: {
                                x: {
                                    min: -5, max: 5, step: 0.001, default: .5
                                },
                                y: {
                                    min: 0.3, max: 3, step: 0.001, default: 2
                                },
                                z: {
                                    min: -5, max: 5, step: 0.001, default: .8
                                }
                            }
                        }
                    }
                }
            },
            {
                visible: false,
                shadowCamera: {
                    near: 1.25,
                    far: 5,
                    left: -1.5,
                    right: 1.8,
                    top: 1.8,
                    bottom: -1.5
                },
                gui: {
                    folderName: 'Direction light BOTTOM',
                    data: {
                        color: 0xffffff,
                        intensity: {
                            min: 0, max: 10, step: 0.001, default: 2.9
                        },
                        position: {
                            data: {
                                x: {
                                    min: -5, max: 5, step: 0.001, default: 0
                                },
                                y: {
                                    min: -5, max: -0.3, step: 0.001, default: -2
                                },
                                z: {
                                    min: -5, max: 5, step: 0.001, default: -2
                                }
                            }
                        }
                    }
                }
            }
        ],
        points: [
            {
                visible: false,
                folderName: 'Point Light TOP',
                data: {
                    color: 0xffffff,
                    intensity: {
                        min: 0, max: 1, step: 0.001, default: .45
                    },
                    distance: {
                        min: 0, max: 10, step: 0.01, default: 3
                    },
                    position: {
                        data: {
                            x: {
                                min: -2, max: 2, step: 0.0001, default: .1
                            },
                            y: {
                                min: -2, max: 2, step: 0.0001, default: 2
                            },
                            z: {
                                min: -2, max: 2, step: 0.0001, default: 0
                            }
                        }
                    }
                }
            },
            {
                visible: false,
                folderName: 'Point Light BOTTOM',
                data: {
                    color: 0xffffff,
                    intensity: {
                        min: 0, max: 1, step: 0.001, default: .5
                    },
                    distance: {
                        min: 0, max: 10, step: 0.01, default: 4
                    },
                    position: {
                        data: {
                            x: {
                                min: -2, max: 2, step: 0.0001, default: -.2
                            },
                            y: {
                                min: -2, max: 2, step: 0.0001, default: -1.5
                            },
                            z: {
                                min: -2, max: 2, step: 0.0001, default: 0
                            }
                        }
                    }
                }
            }
        ],
        spots: [
            {
                visible: true,
                folderName: 'Spot Light TOP',
                data: {
                    color: 0xffffff,
                    intensity: {
                        min: 0, max: 3, step: 0.0001, default: 0.7
                    },
                    distance: {
                        min: 0, max: 10, step: 0.01, default: 3.5
                    },
                    angle: {
                        min: 0, max: Math.PI / 2, step: (Math.PI / 2) / 100, default: 1
                    },
                    penumbra: {
                        min: 0, max: 1, step: 0.001, default: 0.7
                    },
                    decay: {
                        min: 0, max: 1, step: 0.001, default: 1
                    },
                    position: {
                        data: {
                            x: {
                                min: -5, max: 5, step: 0.0001, default: -1
                            },
                            y: {
                                min: -5, max: 5, step: 0.0001, default: 2.5
                            },
                            z: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            }
                        }
                    },
                    target: {
                        data: {
                            x: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            },
                            y: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            },
                            z: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            }
                        }
                    }
                }
            },
            {
                visible: false,
                folderName: 'Spot Light BOTTOM',
                data: {
                    color: 0xffffff,
                    intensity: {
                        min: 0, max: 3, step: 0.0001, default: 2.5
                    },
                    distance: {
                        min: 0, max: 10, step: 0.01, default: 10
                    },
                    angle: {
                        min: 0, max: Math.PI / 2, step: (Math.PI / 2) / 100, default: 0.5
                    },
                    penumbra: {
                        min: 0, max: 1, step: 0.001, default: 0.25
                    },
                    decay: {
                        min: 0, max: 1, step: 0.001, default: 1
                    },
                    position: {
                        data: {
                            x: {
                                min: -5, max: 5, step: 0.0001, default: -0.5
                            },
                            y: {
                                min: -5, max: 5, step: 0.0001, default: -0.5
                            },
                            z: {
                                min: -5, max: 5, step: 0.0001, default: -5
                            }
                        }
                    },
                    target: {
                        data: {
                            x: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            },
                            y: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            },
                            z: {
                                min: -5, max: 5, step: 0.0001, default: 0
                            }
                        }
                    }
                }
            }
        ]
    }
}