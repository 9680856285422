import * as THREE from 'three'


export const addCameraShadowGUI = (guiFolder, shadowCamera, d) => {

    if (shadowCamera.left){
        guiFolder.add(shadowCamera, 'left').min(-(d * 2)).max(-1).step(0.1).name('Shadow camera left')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
    if (shadowCamera.right) {
        guiFolder.add(shadowCamera, 'right').min(1).max((d * 2)).step(0.1).name('Shadow camera right')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
    if (shadowCamera.top) {
        guiFolder.add(shadowCamera, 'top').min(1).max((d * 2)).step(0.1).name('Shadow camera top')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
    if (shadowCamera.bottom) {
        guiFolder.add(shadowCamera, 'bottom').min(-(d * 2)).max(-1).step(0.1).name('Shadow camera bottom')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
    if (shadowCamera.near) {
        guiFolder.add(shadowCamera, 'near').min(0).max(5).step(0.001).name('shadow camera near').name('Shadow camera near')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
    if (shadowCamera.far) {
        guiFolder.add(shadowCamera, 'far').min(0).max(10).step(0.001).name('shadow camera far').name('Shadow camera far')
            .onChange(() => shadowCamera.updateProjectionMatrix())
    }
}

export const generateGuiPanel = (guiObject, object, params, label='') => {
    let guiFolder = params.folderName ? guiObject.addFolder(params.folderName) : guiObject
    let data = params.data
    for (var k in data) {
        if (k == 'color') {
            guiFolder
                .addColor(data, k)
                .onChange(() => { object.color.set(new THREE.Color(data.color)) }).name(k)
        }
        else if (k == 'position') {
            generateGuiPanel(guiFolder, object.position, data[k], 'pos ')
        }
        else if (k == 'rotation') {
            generateGuiPanel(guiFolder, object.rotation, data[k], 'rot ')
        }
        else if (k == 'target') {
            generateGuiPanel(guiFolder, object.target.position, data[k], 'target ')
        }
        else {
            guiFolder
                .add(object, k)
                .min(data[k].min)
                .max(data[k].max)
                .step(data[k].step)
                .name(label + k)
        }
    }
    return guiFolder
}

export default { addCameraShadowGUI, generateGuiPanel }